import "./index.scss";
import React, { useState, useEffect } from 'react';
import axios from "../../util/axios";
import Empty from "../../components/empty";
import { useNavigate } from 'react-router-dom';
import { Toast } from "antd-mobile";
import _ from "lodash";
function Community() {
    const navigate = useNavigate();
    let [state, setState] = useState({
        cate: [{
            name: '全部',
            id: 0
        }, {
            name: '关注',
            id: 1
        }],
        list: [],
        cate_id: '',
        page: 1,
        total: 0
    })
    let is_getList = !0;
    let getList = () => {
        let data = {
            page: state.page,
            rows: 10,
        }
        if (state.cate_id == 1) data.is_follow = 1;
        if (state.cate_id > 1) data.cate_id = state.cate_id;
        axios.post("/JuTwitter/ForumsPosts/index", data).then(res => {
            if (res.code == 0) {
                let _state = _.cloneDeep(state);
                let list = res.result.post.list;
                if (list.length) {
                    if (_state.cate.length < 3) _state.cate.push(...res.result.cate.list);
                    _state.list.push(...list);
                    _state.page++;
                    _state.total = res.result.post.total;
                    setState(_state);
                }
            }
        })
    }
    let like = (e, index) => {
        e.stopPropagation();
        if (!state.list[index].is_like) {
            axios.post("/JuTwitter/ForumsPosts/postlike", {
                id: state.list[index].id
            }).then(res => {
                if (res.code == 0) {
                    let _state = _.cloneDeep(state);
                    _state.list[index].is_like = 1;
                    _state.list[index].like_num++;
                    setState(_state)
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        }
    }
    useEffect(() => {
        getList();
    }, [state.cate_id])
    return (
        <div className="community" onScroll={(e) => {
            let { clientHeight, scrollHeight, scrollTop } = e.target;
            if (scrollTop + clientHeight + 20 > scrollHeight) {
                if (is_getList) {
                    is_getList = !1;
                    getList();
                }
            }
        }}>
            <div className="cate">
                {
                    state.cate.map((item, index) => {
                        return (
                            <div className={`${item.id == state.cate_id ? 'active' : ''}`} key={index} onClick={() => {
                                if (state.cate_id != item.id) {
                                    setState({
                                        ...state,
                                        cate_id: item.id,
                                        list: [],
                                        page: 1,
                                        total: 0
                                    })
                                }
                            }}>{item.name}</div>
                        )
                    })
                }
            </div>
            <div className="list">
                <div className="left">
                    {
                        state.list.map((item, index) => {
                            return Number(index + 1) % 2 == 1 ? <div className="item" key={index} onClick={() => navigate("/communityDetails", { state: item.id })}>
                                <img className="cover" src={item.cover} alt="" />
                                <div className="title">{item.title}</div>
                                <div className="date">
                                    <div>
                                        <img src={item.avater} alt="" />
                                        <span>{item.nick_name}</span>
                                    </div>
                                    <div>
                                        <img src={item.is_like ? require("../../image/community/icon1.png") : require("../../image/community/icon7.png")} onClick={(e) => like(e, index)} />
                                        <span>{item.like_num >= 1000 ? parseFloat((item.like_num / 1000).toFixed(2)) + 'k' : item.like_num}</span>
                                    </div>
                                </div>
                            </div> : null
                        })
                    }
                </div>
                <div className="right">
                    {
                        state.list.map((item, index) => {
                            return Number(index + 1) % 2 == 0 ? <div className="item" key={index} onClick={() => navigate("/communityDetails", { state: item.id })}>
                                <img className="cover" src={item.cover} alt="" />
                                <div className="title">{item.title}</div>
                                <div className="date">
                                    <div>
                                        <img src={item.avater} alt="" />
                                        <span>{item.nick_name}</span>
                                    </div>
                                    <div>
                                        <img src={item.is_like ? require("../../image/community/icon1.png") : require("../../image/community/icon7.png")} onClick={(e) => like(e, index)} />
                                        <span>{item.like_num >= 1000 ? parseFloat((item.like_num / 1000).toFixed(2)) + 'k' : item.like_num}</span>
                                    </div>
                                </div>
                            </div> : null
                        })
                    }
                </div>
                {
                    state.list.length == 0 ? <Empty text="暂无内容" marginTop={180 / 37.5 + 'rem'} /> : null
                }

            </div>
            {
                state.total == state.list.length && state.list.length != 0 ? <div className="no">- 没有更多啦 -</div> : null
            }
        </div>
    )
}


export default Community;