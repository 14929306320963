import "./index.scss";
import React, { useState, useEffect } from 'react';
import axios from "../../util/axios";
import { Checkbox, Stepper, Toast, SwipeAction, Dialog } from 'antd-mobile'
import { useNavigate } from "react-router-dom";
import _ from "lodash";
function Cart() {
    const navigate = useNavigate();
    let [state, setState] = useState({
        list: [],
        total_num: 0,
        is_all: !0,
        total_price: 0,
        check_total_num: 0,
        is_edit: !1
    })
    let [randomGoods, setRandomGoods] = useState({
        list: [],
        page: 1,
        is_getList: !0,
        total_number: 0
    });
    let getCartList = () => {
        axios.post("/JuTwitter/Cart/list").then(res => {
            if (res.code == 0) {
                let list = res.result.list;
                let total_price = 0;
                for (let i in list) {
                    list[i].is_all = !0;
                    for (let y in list[i].cart_info) {
                        list[i].cart_info[y].is_selected = !0;
                        if (list[i].cart_info[y].goods_spec_cps) {
                            list[i].cart_info[y].goods_price = list[i].cart_info[y].goods_spec_cps.new_sale_price;
                            list[i].cart_info[y].goods_skus_cps_id = list[i].cart_info[y].goods_spec_cps.id;
                        }
                        total_price = total_price + list[i].cart_info[y].goods_price * list[i].cart_info[y].goods_num;
                    }
                }
                setState({
                    ...state,
                    list: list,
                    total_num: res.result.total_num,
                    check_total_num: res.result.total_num,
                    total_price: parseFloat(total_price.toFixed(2))
                })
            }
        })
    }
    let getRandomGoods = () => {
        axios.post("/JuTwitter/Cart/getRandomGoods", {
            page: randomGoods.page,
            rows: 10,
        }).then(res => {
            if (res.code == 0) {
                let _randomGoods = _.cloneDeep(randomGoods);
                let list = res.result.random_goods.list;
                _randomGoods.total_number = res.result.random_goods.total_number;
                if (list.length) {
                    list.map(item => {
                        item.head_imgs = JSON.parse(item.head_imgs);
                    })
                    _randomGoods.page++;
                    _randomGoods.is_getList = !0;
                    _randomGoods.list.push(...list);
                }
                setRandomGoods(_randomGoods);
            }
        })
    }
    let settlement = () => {
        let list = state.list;
        let shopList = [];
        let sku_json = [];
        for (let i in list) {
            let obj = {
                goodsList: [],
                goods_amount: 0
            };
            for (let k in list[i].cart_info) {
                let cart_info = list[i].cart_info[k];
                if (cart_info.is_selected) {
                    obj.shop_logo = list[i].shop_info.shop_logo;
                    obj.shop_name = list[i].shop_info.shop_name;
                    obj.company_id = list[i].shop_info.id;
                    obj.goods_amount = parseFloat((obj.goods_amount + (cart_info.goods_num * cart_info.goods_price)).toFixed(2))
                    obj.goodsList.push({
                        price: cart_info.goods_price,
                        num: cart_info.goods_num,
                        img: cart_info.goods_sku_img,
                        title: cart_info.goods_title,
                        sku_name: cart_info.goods_spec.spec_title[0].attr_value
                    });
                    let sku_json_obj = {
                        sku_id: cart_info.goods_sku_id,
                        company_id: cart_info.company_id,
                        goods_type: 2,
                        goods_num: cart_info.goods_num,
                        goods_id: cart_info.goods_id
                    }
                    if (cart_info.goods_skus_cps_id) sku_json_obj.goods_skus_cps_id = cart_info.goods_skus_cps_id
                    sku_json.push(sku_json_obj);
                }
            }
            if (obj.company_id) shopList.push(obj);
        }
        navigate("/confirmGoodsOrder", {
            state: {
                cart_way: 'cart',
                company_id: shopList.length > 1 ? 0 : shopList[0].company_id,
                goods_total_num: state.check_total_num,
                shopList,
                sku_json
            }
        })
    }
    let removeCart = (type, index, y, cart_ids) => {
        let _state = _.cloneDeep(state);
        if (type == 1) {
            Dialog.confirm({
                content: `您确定删除选中的${_state.list[index].cart_info[y].goods_num}件商品`,
                onConfirm: () => {
                    axios.post("/JuTwitter/Cart/del", {
                        cart_ids: cart_ids
                    }).then(res => {
                        if (res.code == 0) {
                            _state.total_price = _state.total_price - _state.list[index].cart_info[y].goods_num * _state.list[index].cart_info[y].goods_price;
                            _state.check_total_num = _state.check_total_num - _state.list[index].cart_info[y].goods_num;
                            _state.total_num = _state.total_num - _state.list[index].cart_info[y].goods_num;
                            _state.total_price = parseFloat(_state.total_price.toFixed(2));
                            _state.list[index].cart_info.splice(y, 1);
                            if (!_state.list[index].cart_info.length) _state.list.splice(index, 1);
                            setState(_state);
                            Toast.show({
                                content: "删除成功",
                                icon: "success"
                            })
                        } else {
                            Toast.show({
                                content: res.msg
                            })
                        }
                    })
                }
            })
        }
        if (type == 2) {
            let cart_ids = "";
            for (let i in _state.list) {
                for (let k in _state.list[i].cart_info) {
                    if (_state.list[i].cart_info[k].is_selected) {
                        cart_ids += _state.list[i].cart_info[k].id + ','
                    }
                }
            }
            cart_ids = cart_ids.substring(0, cart_ids.length - 1);
            if (!cart_ids.length) return Toast.show({ content: "请选择要删除的商品" })
            Dialog.confirm({
                content: `您确定删除选中的${state.check_total_num}件商品`,
                onConfirm: () => {
                    axios.post("/JuTwitter/Cart/del", {
                        cart_ids: cart_ids
                    }).then(res => {
                        if (res.code == 0) {
                            cart_ids = cart_ids.split(',');
                            for (let i = 0; i < _state.list.length; i++) {
                                for (let k = 0; k < _state.list[i].cart_info.length; k++) {
                                    for (let y in cart_ids) {
                                        _state.list[i].cart_info = _state.list[i].cart_info.filter(item => item.id != cart_ids[y])
                                    }
                                }
                            }
                            _state.list = _state.list.filter(item => item.cart_info.length);
                            _state.check_total_num = 0;
                            _state.total_price = 0;
                            let total_num = 0;
                            for (let i in _state.list) {
                                for (let k in _state.list[i].cart_info) {
                                    total_num = total_num + _state.list[i].cart_info[k].goods_num
                                }
                            }
                            _state.total_num = total_num;
                            setState(_state);
                            Toast.show({
                                content: "删除成功",
                                icon: "success"
                            })
                        } else {
                            Toast.show({
                                content: res.msg
                            })
                        }
                    })
                },
            })

        }
    }
    useEffect(() => {
        !randomGoods.is_getList && getRandomGoods();
    }, [randomGoods.is_getList])
    useEffect(() => {
        getCartList();
        getRandomGoods();
    }, [])
    return (
        <div className="cart" onScroll={(e) => {
            let { clientHeight, scrollHeight, scrollTop } = e.target;
            if (scrollTop + clientHeight + 20 > scrollHeight) {
                if (randomGoods.is_getList) {
                    setRandomGoods({
                        ...randomGoods,
                        is_getList: !1
                    })
                }
            }
        }}>
            {
                state.list.length ? <div className="total-num">
                    <span>共{state.total_num}件商品</span>
                    <span onClick={() => setState({ ...state, is_edit: !state.is_edit })}>{state.is_edit ? '完成' : '编辑'}</span>
                </div> : null
            }
            <div className="list">
                {
                    state.list.map((item, index) => {
                        return (
                            <div className="shop" key={index}>
                                <div className="shop-name">
                                    <Checkbox checked={item.is_all} onChange={() => {
                                        let total_price = 0;
                                        let check_total_num = 0;
                                        let _state = _.cloneDeep(state);
                                        let is_all = !item.is_all;
                                        if (is_all) {
                                            _state.list[index].cart_info.map(item => item.is_selected = !0)
                                        } else {
                                            _state.list[index].cart_info.map(item => item.is_selected = !1)
                                        }
                                        _state.list[index].is_all = is_all;
                                        let check_cart_info = _state.list.filter(item => item.is_all);
                                        check_cart_info.length == _state.list.length ? _state.is_all = !0 : _state.is_all = !1
                                        for (let i in _state.list) {
                                            for (let k in _state.list[i].cart_info) {
                                                if (_state.list[i].cart_info[k].is_selected) {
                                                    total_price = total_price + _state.list[i].cart_info[k].goods_price * _state.list[i].cart_info[k].goods_num;
                                                    check_total_num = check_total_num + _state.list[i].cart_info[k].goods_num;
                                                }
                                            }
                                        }
                                        _state.check_total_num = check_total_num;
                                        _state.total_price = parseFloat(total_price.toFixed(2));
                                        setState(_state);

                                    }} />
                                    <img className="icon1" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/cartStoreIcon.png" alt="" />
                                    <p>{item.shop_info.shop_name}</p>
                                    <img className="icon2" src={require("../../image/pointsMall/icon8.png")} alt="" />
                                </div>
                                {
                                    item.cart_info.map((goods, y) => {
                                        return (
                                            <SwipeAction rightActions={[{
                                                key: 'delete',
                                                text: '删除',
                                                color: '#ff1607',
                                                onClick() {
                                                    removeCart(1, index, y, goods.id);
                                                }

                                            }]} key={y}>
                                                <div className="item" >
                                                    <Checkbox checked={goods.is_selected} onChange={() => {
                                                        let total_price = 0;
                                                        let check_total_num = 0;
                                                        let _state = _.cloneDeep(state);
                                                        let cart_info = _state.list[index].cart_info;
                                                        cart_info[y].is_selected = !goods.is_selected;
                                                        let checkGoods = cart_info.filter(item => item.is_selected);
                                                        if (checkGoods.length == cart_info.length) {
                                                            _state.list[index].is_all = !0;
                                                        } else {
                                                            _state.list[index].is_all = !1;
                                                        }
                                                        let check_cart_info = _state.list.filter(item => item.is_all);
                                                        check_cart_info.length == _state.list.length ? _state.is_all = !0 : _state.is_all = !1
                                                        for (let i in _state.list) {
                                                            for (let k in _state.list[i].cart_info) {
                                                                if (_state.list[i].cart_info[k].is_selected) {
                                                                    total_price = total_price + _state.list[i].cart_info[k].goods_price * _state.list[i].cart_info[k].goods_num;
                                                                    check_total_num = check_total_num + _state.list[i].cart_info[k].goods_num;
                                                                }
                                                            }
                                                        }
                                                        _state.check_total_num = check_total_num;
                                                        _state.total_price = parseFloat(total_price.toFixed(2));
                                                        setState(_state);
                                                    }} />
                                                    <img src={goods.goods_sku_img} alt="" />
                                                    <div className="goods-con">
                                                        <div className="title">{goods.goods_title}</div>
                                                        <div className="sku">{goods.goods_spec.spec_title[0].attr_value}</div>
                                                        <div className="price-num">
                                                            <div className="price">￥ {parseFloat((Number(goods.goods_price) * goods.goods_num).toFixed(2))}</div>
                                                            <Stepper
                                                                value={goods.goods_num}
                                                                min={1}
                                                                onChange={value => {
                                                                    axios.post("/JuTwitter/Cart/edit", {
                                                                        quantity: value,
                                                                        cart_id: goods.id
                                                                    }).then(res => {
                                                                        if (res.code == 0) {
                                                                            let _state = _.cloneDeep(state);
                                                                            let add_num = value - _state.list[index].cart_info[y].goods_num;
                                                                            _state.list[index].cart_info[y].goods_num = value;
                                                                            _state.total_num = Number(_state.total_num) + add_num;
                                                                            if (goods.is_selected) {
                                                                                _state.check_total_num = Number(_state.check_total_num) + add_num;
                                                                                _state.total_price = parseFloat((_state.total_price + Number(_state.list[index].cart_info[y].goods_price * add_num)).toFixed(2));
                                                                            }
                                                                            setState(_state);
                                                                        } else {
                                                                            Toast.show({
                                                                                content: res.msg
                                                                            })
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwipeAction>
                                        )
                                    })
                                }

                            </div>
                        )
                    })
                }
                {
                    !state.list.length ? <div className="cart-no">
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/p_cartIcon.png" />
                        <p>购物车空空的</p>
                    </div> : null
                }
            </div>
            <div className="random-goods">
                <div className="title">更多精选商品</div>
                <div className="random-goods-list">
                    {
                        randomGoods.list.map((item, index) => {
                            return (
                                <div className="item" key={index} onClick={() => navigate("/enterpriseGoodsDetails", { state: item.id })}>
                                    <img src={item.head_imgs[0]} alt="" />
                                    <div className="goods-con">
                                        <div>{item.title}</div>
                                        <div>{item.sub_title}</div>
                                        <div>{item.goods_price}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    randomGoods.total_number == randomGoods.list.length ? <div className="no">— 没有更多啦 —</div> : null
                }
            </div>
            <div className="operate">
                <div className="left">
                    <Checkbox style={{ '--font-size': 14 / 37.5 + 'rem', color: "#999" }} checked={state.is_all} onChange={() => {
                        let total_price = 0;
                        let is_all = !state.is_all;
                        let _state = _.cloneDeep(state);
                        if (is_all) {
                            for (let i in _state.list) {
                                _state.list[i].is_all = !0;
                                for (let k in _state.list[i].cart_info) {
                                    _state.list[i].cart_info[k].is_selected = !0;
                                    total_price = total_price + _state.list[i].cart_info[k].goods_price * _state.list[i].cart_info[k].goods_num;
                                }
                            }
                            _state.check_total_num = _state.total_num;
                        } else {
                            for (let i in _state.list) {
                                _state.list[i].is_all = !1;
                                for (let k in _state.list[i].cart_info) _state.list[i].cart_info[k].is_selected = !1;
                            }
                            _state.check_total_num = 0;
                        }
                        _state.is_all = is_all;
                        _state.total_price = parseFloat(total_price.toFixed(2));
                        setState(_state);
                    }}>全选</Checkbox>
                    {
                        state.is_edit ? <div className="check-num">已选：{state.check_total_num}件</div> : <div className="total-price">
                            合计：
                            <span>￥{state.total_price}</span>
                        </div>
                    }
                </div>
                <div className="right">
                    {
                        state.is_edit ? <div className="remove" onClick={() => removeCart(2)}>删除</div> : <div className="settlement" onClick={() => settlement()}>结算</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Cart;