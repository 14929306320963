
import React, { useState, useEffect } from 'react';
import style from "./index.module.scss";
import axios from '../../../util/axios';
import { Toast } from 'antd-mobile'
import { useNavigate } from "react-router-dom";
import _ from "lodash";
function PointsMall() {
    const navigate = useNavigate();
    let [state, setState] = useState({
        integral_task: [{
            name: '购买商品得积分',
            btn: '去购买',
            type: 1,
            icon: require("../../../image/pointsMall/icon3.png")
        }, {
            name: '购买生活服务得积分',
            btn: '去购买',
            type: 2,
            icon: require("../../../image/pointsMall/icon4.png")
        }, {
            name: '分享店铺链接',
            btn: '去分享',
            type: 3,
            icon: require("../../../image/pointsMall/icon5.png")
        }, {
            name: '社区种草',
            btn: '去分享',
            type: 4,
            icon: require("../../../image/pointsMall/icon6.png")
        }, {
            name: '连接wifi得积分',
            btn: '去连接',
            type: 5,
            icon: require("../../../image/pointsMall/icon7.png")
        }, {
            name: '社区种草',
            btn: '去分享',
            type: 6,
            icon: require("../../../image/pointsMall/icon6.png")
        }],
        task_num: 2,
        total_number: 0,
        page: 1,
        showHand: !1
    })
    let [shopInfo, setShopInfo] = useState({});
    let is_getGoods = !0;
    let [goodsList, setGoodsList] = useState([])
    let [integral, setIntegral] = useState(0);
    let getIntegral = () => {
        axios.post("/JuTwitter/Integral/getIntegrale").then(res => {
            if (res.code == 0) {
                setIntegral(res.result.integral);
            }
        })
    }
    let signIn = () => {
        axios.post("/JuTwitter/Integral/signIn", {
        }).then(res => {
            if (res.code == 0) {
                if (res.result.integral) {
                    Toast.show({
                        content: "签到成功积分+" + res.result.integral,
                        icon: 'success'
                    })
                }
                getIntegral();
            }
        })
    }
    let getGoods = () => {
        axios.post("/JuTwitter/Integral/integralGoodsList", {
            page: state.page,
            rows: 10
        }).then(res => {
            if (res.code == 0) {
                let list = res.result.list;
                let goods_list = _.cloneDeep(goodsList);
                if (list.length) {
                    goods_list.push(...list);
                    setGoodsList(goods_list);
                    setState({
                        ...state,
                        page: state.page + 1,
                        total_number: res.result.total_number
                    })
                }
            }
        })
    }
    let taskGo = (type) => {
        if (type == 1 || type == 2) {
            navigate("/")
        }
        if (type == 3) {
            if (!state.showHand) {
                setState({
                    ...state,
                    showHand: !0
                })
                setTimeout(() => {
                    setState({
                        ...state,
                        showHand: !1
                    })
                }, 8000);
            }
        }
        if(type == 4 || type == 6) navigate("/community")
    }
    let getShopInfo = () => {
        axios.post("/JuTwitter/Page/index").then(res => {
            if (res.code === 0) {
                let result = res.result;
                result.wifi_appid.path = `${result.wifi_appid.path}.html?sn=${localStorage.getItem("sn")}`;
                setShopInfo(result);
            }
        })
    }
    useEffect(() => {
        getGoods();
        signIn();
        getShopInfo();
    }, [])
    return (
        <>
            <div className={style.content} onScroll={(e) => {
                let { clientHeight, scrollHeight, scrollTop } = e.target;
                if (scrollTop + clientHeight + 20 > scrollHeight) {
                    if (is_getGoods) {
                        is_getGoods = !1;
                        getGoods();
                    }
                }
            }}>
                <div className={style['integral-info']} style={{ backgroundImage: "url(" + require("../../../image/pointsMall/back.png") + ")" }}>
                    <div>当前可用积分</div>
                    <div>{integral}</div>
                    <div onClick={() => navigate("/integralDetails", { state: integral })}>积分明细</div>
                </div>
                <div className={style['integral-task']}>
                    <div className={style.title}>做任务 领积分</div>
                    <div className={style['task-list']}>
                        {
                            state.integral_task.slice(state.task_num - 2, state.task_num).map((item, index) => {
                                return (
                                    <div className={style.item} key={index} onClick={() => taskGo(item.type)}>
                                        {
                                            item.type == 5 ? <wx-open-launch-weapp
                                                id="launch-btn"
                                                username={shopInfo.wifi_appid.original_id}
                                                path={shopInfo.wifi_appid.path}
                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                            >
                                                <script type="text/wxtag-template">
                                                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                                </script>
                                            </wx-open-launch-weapp> : null
                                        }
                                        <div className={style.left}>
                                            <img className={style.icon} src={item.icon} />
                                            <div className={style.name}>
                                                <div>{item.name}</div>
                                                <div>
                                                    <img src={require("../../../image/pointsMall/icon1.png")} alt="" />
                                                    <span>积分奖励不限</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={style.right}>{item.btn}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={style.refresh} >
                        <img src={require("../../../image/pointsMall/icon2.png")} alt="" />
                        <span onClick={() => {
                            let task_num = state.task_num;
                            if (task_num == 6) {
                                task_num = 2;
                            } else {
                                task_num += 2;
                            }
                            setState({
                                ...state,
                                task_num
                            })
                        }}>换一批</span>
                    </div>
                    {
                        state.showHand ? <img className={style.hand} src={require("../../../image/pointsMall/icon12.png")} alt="" /> : null
                    }
                </div>
                <div className={style['goods']}>
                    <div className={style.title}>
                        <span>兑换商品</span>
                        <span onClick={() => navigate("/integalOrder")}>积分订单</span>
                    </div>
                    <div className={style.list}>
                        {
                            goodsList.map((item, index) => {
                                return (
                                    <div className={style.item} key={index} onClick={() => navigate("/goodsDetails", { state: item.id })}>
                                        <img src={item.cover_picture} alt="" />
                                        <div>{item.name}</div>
                                        <div>
                                            <span>{item.integral_amount}积分</span>
                                            {Number(item.price) ? <span>+￥{item.price}</span> : null}
                                        </div>
                                        <div>立即兑换</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    state.total_number == goodsList.length ? <div className={style.no}>- 已经到底了 -</div> : null
                }
            </div>
        </>
    )
}

export default PointsMall;