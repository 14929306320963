import React, { useState } from 'react'
import { Input, Button, Image, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import axios from '../../../util/axios'
import { handleLocationPath } from '../../../util/utils'
import './index.scss'
function Login() {
  const navigate = useNavigate()
  let { type } = handleLocationPath()
  let [state, setState] = useState({
    staff_mobile: '',
    password: ''
  })
  let login = () => {
    if (!state.staff_mobile) return Toast.show({ content: '请输入手机号码' })
    if (!state.password) return Toast.show({ content: '请输入登录密码' })
    axios.post('/Staff/Login/login', state).then((res) => {
      if (res.code == 0) {
        sessionStorage.setItem('token', res.result.token)
        if (type) {
          navigate('/businessList')
        } else {
          navigate('/addServiceProvider')
        }
      } else {
        Toast.show({
          content: res.msg
        })
      }
    })
  }
  return (
    <>
      <div className="back">
        <Image src={require('../../../image/incoming/back.png')} />
      </div>
      <div className="login">
        <div className="form-item">
          <Image src={require('../../../image/incoming/user.png')} />
          <Input value={state.staff_mobile} onChange={(e) => setState({ ...state, staff_mobile: e })} placeholder="请输入手机号码" />
        </div>
        <div className="form-item">
          <Image src={require('../../../image/incoming/pass.png')} />
          <Input value={state.password} onChange={(e) => setState({ ...state, password: e })} placeholder="请输入登录密码" type="password" />
        </div>
        <div className="btn">
          <Button block color="primary" size="large" onClick={() => login()}>
            登录
          </Button>
        </div>
      </div>
    </>
  )
}

export default Login
