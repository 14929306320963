import { combineReducers } from 'redux';
const getShowTabbar = (state = {}, action) => {
  switch (action.type) {
    case "setShowTabbar":
      state = action.data
      return state;
    default:
      return state;
  }
}

const reducer = combineReducers({
  showTabbar: getShowTabbar
})

export default reducer

