import './index.scss'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from '../../../util/axios'
import _ from 'lodash'
import { getDateformat } from '../../../util/utils'
import { uniacid } from '../../../util/config'
function IntegralDetails() {
  const location = useLocation()
  let [state, setState] = useState({
    tabs: [
      {
        name: '全部',
        income_type: 0
      },
      {
        name: '收入',
        income_type: 1
      },
      {
        name: '支出',
        income_type: 2
      }
    ],
    income_type: 0,
    page: 1,
    total_number: 0
  })
  let [list, setList] = useState([])
  let is_getList = !0
  let selectType = (income_type) => {
    if (income_type != state.income_type) {
      setList([])
      setState({
        ...state,
        income_type: income_type,
        page: 1
      })
    }
  }
  let getList = () => {
    axios
      .post('/JuTwitter/Integral/integralLog', {
        uniacid: uniacid,
        income_type: state.income_type,
        rows: 20,
        page: state.page
      })
      .then((res) => {
        if (res.code == 0) {
          let data = res.result.list
          let lists = _.cloneDeep(list)
          if (data.length) {
            lists.push(...data)
            setList(lists)
            setState({
              ...state,
              page: state.page + 1,
              total_number: res.result.total_number
            })
          }
        }
      })
  }
  useEffect(() => {
    getList()
  }, [state.income_type])
  useEffect(() => {}, [])
  return (
    <div
      className="integral-details"
      onScroll={(e) => {
        let { clientHeight, scrollHeight, scrollTop } = e.target
        if (scrollTop + clientHeight + 20 > scrollHeight) {
          if (is_getList) {
            is_getList = !1
            getList()
          }
        }
      }}
    >
      <div className="integral-info" style={{ backgroundImage: 'url(' + require('../../../image/pointsMall/back1.png') + ')' }}>
        <div>当前可用积分</div>
        <div>{location.state || 0}</div>
      </div>
      <div className="integral-list">
        <img src={require('../../../image/pointsMall/icon10.png')} alt="" />
        <div className="title">积分明细</div>
        <div className="tabs">
          {state.tabs.map((item, index) => {
            return (
              <div key={index} className={`${item.income_type == state.income_type ? 'active' : ''}`} onClick={() => selectType(item.income_type)}>
                {item.name}
              </div>
            )
          })}
        </div>
        <div className="list">
          {list.map((item, index) => {
            return (
              <div className="item" key={index}>
                <div>
                  <p>
                    {item.type == 1
                      ? '每日签到'
                      : item.type == 2
                      ? '摇奖'
                      : item.type == 3
                      ? '第三方活动'
                      : item.type == 4
                      ? '连接wifi'
                      : item.type == 5
                      ? '扫码支付'
                      : item.type == 6
                      ? '扫码点餐'
                      : item.type == 7
                      ? '分享店铺'
                      : item.type == 8
                      ? '种草'
                      : '兑换商品'}
                  </p>
                  <p>{getDateformat(item.create_time)}</p>
                </div>
                <div>
                  {item.income_type == 1 ? '+' : '-'}
                  {item.integral}
                </div>
              </div>
            )
          })}
          {state.total_number == list.length ? <div className="no">- 没有更多啦 -</div> : null}
        </div>
      </div>
    </div>
  )
}

export default IntegralDetails
