import React, { useEffect } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
function Map() {
  const navigate = useNavigate()
  useEffect(() => {
    let activation = JSON.parse(localStorage.getItem('activation'))
    window.addEventListener(
      'message',
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data
        if (loc && loc.module == 'locationPicker') {
          //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          console.log('location', loc)
          activation.store_longitude = loc.latlng.lng
          activation.store_latitude = loc.latlng.lat
          localStorage.setItem('activation', JSON.stringify(activation))
          navigate('/activation', { state: 4 })
        }
      },
      false
    )
  }, [])
  return (
    <>
      <iframe
        id="mapPage"
        width="100%"
        height="100%"
        src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=XFJBZ-KMPWC-TE226-ABZZG-J3YW2-HLBDZ&referer=地图应用"
        allow="geolocation"
        title="navigation"
      ></iframe>
    </>
  )
}

export default Map
