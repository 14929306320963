import style from "./index.module.scss";
import React, { useState, useEffect } from 'react';
import axios from "../../util/axios";
import { useNavigate,useLocation } from "react-router-dom";
import { RightOutline } from 'antd-mobile-icons';
import { Toast } from 'antd-mobile'
function Withdrawal() {
    const location = useLocation();
    const navigate = useNavigate();
    let [cardInfo, setCardInfo] = useState(null);
    let [withdrawal_amount,set_withdrawal_amount] = useState('');
    let getCardInfo = () => {
        axios.post("/JuTwitter/User/getBankCard").then(res => {
            if (res.code == 0) {
                if (res.result) {
                    setCardInfo(res.result);
                }
            }
        })
    }
    let submit = ()=>{
        if(!withdrawal_amount)return Toast.show({content:"请输入提现金额"});
        if(withdrawal_amount < location.state.minimum_withdrawal_amount) return Toast.show({content:"提现金额不能小于" + location.state.minimum_withdrawal_amount});
        if(withdrawal_amount > location.state.cash)return Toast.show({content:"已超出可提现金额，请重新输入"});
        axios.post("/JuTwitter/User/withdraw",{
            withdrawal_amount:withdrawal_amount
        }).then(res=>{
            if(res.code === 0){
                navigate("/withdrawalLog");
            }else{
                Toast.show({
                    content:res.msg
                })
            }
        })
    }
    useEffect(() => {
        getCardInfo();
    }, [])
    return (
        <div className={style.content}>
            <div className={style.card}>
                <div>
                    <span>提现至 {cardInfo ? cardInfo.bank_card.substring(cardInfo.bank_card.length - 4) : null}</span>
                    <span></span>
                </div>
                <div onClick={() => navigate("/addCard")}>
                    <span>{cardInfo ? '编辑银行储蓄卡' : '添加银行储蓄卡'}</span>
                    <RightOutline color="#999" fontSize={14} />
                </div>
            </div>
            <div className={style.withdrawal_money}>
                <div className={style.title}>提现金额</div>
                <div className={style.put}>
                    <div>
                        <span>￥</span>
                        <input type="number" placeholder="请输入" value={withdrawal_amount} onChange={(e)=> set_withdrawal_amount(e.target.value)} />
                    </div>
                    <div>余额满{location.state.minimum_withdrawal_amount}可提现</div>
                </div>
                <div className={style.can_withdrawal}>
                    <span>可提现余额￥{location.state.cash}</span><span onClick={()=>set_withdrawal_amount(location.state.cash)}>全部提现</span>
                </div>
            </div>
            <div className={style.btn} onClick={()=>submit()}>
                确认提现
            </div>
            <div className={style.time}>
                24小时内到账
            </div>
        </div>
    )
}

export default Withdrawal;