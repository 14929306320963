import './index.scss'
import React, { useState, useEffect } from 'react'
import { Input, Checkbox, Cascader, Toast } from 'antd-mobile'
import axios from '../../util/axios'
import { useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { uniacid } from '../../util/config'
function AddAddress() {
  const [visible, setVisible] = useState(false)
  const [area, setArea] = useState([])
  const [areaVal, setAreaVal] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  let [state, setState] = useState({
    id: '',
    is_default: !0,
    name: '',
    mobile: '',
    province_id: '',
    city_id: '',
    area_id: '',
    address: '',
    full_name_address: ''
  })
  let [deleteModel, setDeleteModel] = useState(!1)
  let confirmArea = (value) => {
    if (value.length > 2) {
      let province = area.filter((item) => item.name == value[0])
      let city = province[0].children.filter((item) => item.name == value[1])
      let _area = city[0].children.filter((item) => item.name == value[2])
      setAreaVal(value)
      setVisible(false)
      setState({
        ...state,
        province_id: province[0].id,
        city_id: city[0].id,
        area_id: _area[0].id,
        full_name_address: value.join('')
      })
    }
  }
  let getArea = (pid, is_children = 1) => {
    return new Promise((resolve) => {
      axios.post('/JuTwitter/Area/index', pid ? { pid: pid } : null).then((res) => {
        if (res.code == 0) {
          let list = res.result.list
          for (let i in list) {
            list[i].label = list[i].name
            list[i].value = list[i].name
            if (is_children) list[i].children = []
          }
          if (list.length) resolve(list)
        }
      })
    })
  }
  let fetchOptionsForValue = async (v, level) => {
    let _area = _.cloneDeep(area)
    _area.map((item) => {
      if (level == 1) {
        if (item.name == v && !item.children.length) {
          getArea(item.id).then((res) => {
            item.children = res
            setArea(_area)
          })
        }
      }
      if (level == 2 && item.children) {
        item.children.map((row) => {
          if (row.name == v && !row.children.length) {
            getArea(row.id, 0).then((res) => {
              row.children = res
              setArea(_area)
            })
          }
        })
      }
    })
  }
  let getInfo = () => {
    axios
      .post('/JuTwitter/Address/info', {
        uniacid: uniacid,
        id: location.state
      })
      .then((res) => {
        if (res.code == 0) {
          console.log(res)
          res.result.is_default = res.result.is_default ? 1 : 0
          setState(res.result)
        }
      })
  }
  let save = () => {
    try {
      if (!state.name) throw '请填写收件人姓名'
      if (!state.mobile) throw '请填写收件人电话号码'
      if (!state.city_id) throw '请选择所在区域'
      if (!state.address) throw '请填写详细地址'
      let data = {
        uniacid: uniacid,
        is_default: state.is_default ? 1 : 0,
        name: state.name,
        mobile: state.mobile,
        province_id: state.province_id,
        city_id: state.city_id,
        area_id: state.area_id,
        address: state.address
      }
      if (state.id) data.id = state.id
      axios.post(state.id ? '/JuTwitter/Address/edit' : '/JuTwitter/Address/add', data).then((res) => {
        if (res.code == 0) {
          Toast.show({
            content: state.id ? '修改地址成功' : '添加地址成功',
            icon: 'success'
          })
          navigate(-1)
        } else {
          Toast.show({
            content: res.msg
          })
        }
      })
    } catch (e) {
      Toast.show({
        content: e
      })
    }
  }
  useEffect(() => {
    if (location.state) getInfo()
    getArea().then((res) => {
      setArea(res)
    })
  }, [])
  return (
    <div className="addAddress">
      <div className="notice">
        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/a_notice.png" className="notice_iamge" />
        <div className="notice_text">温馨提醒：为了不影响订单的派送，最好填写真实姓名哦</div>
      </div>
      <div className="item bb">
        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/a_name.png" className="item_iamge" />
        <div className="item_label">收件人</div>
        <Input className="item_input" value={state.name} onChange={(e) => setState({ ...state, name: e })} placeholder="收件人姓名" placeholder-style="color: #999999;" />
      </div>
      <div className="item bb">
        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/a_phone.png" className="item_iamge" />
        <div className="item_label">电话号码</div>
        <Input className="item_input" value={state.mobile} onChange={(e) => setState({ ...state, mobile: e })} type="number" placeholder="收件人电话号码" placeholder-style="color: #999999;" />
      </div>
      <div className="item bb" onClick={() => setVisible(true)}>
        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/a_name.png" className="item_iamge" />
        <div style={{ flex: !state.full_name_address ? 1 : '' }}>所在区域</div>
        {state.full_name_address ? (
          <div className="item_label" style={{ textAlign: 'right', marginRight: '5px' }}>
            {state.full_name_address}
          </div>
        ) : null}
        <img src={require('../../image/pointsMall/icon8.png')} className="rigth" />
        <Cascader
          options={area}
          visible={visible}
          onCancel={() => setVisible(false)}
          value={areaVal}
          onConfirm={(value) => confirmArea(value)}
          onSelect={(value) => {
            if (value.length == 1) {
              fetchOptionsForValue(value[0], 1)
            }
            if (value.length == 2) {
              fetchOptionsForValue(value[1], 2)
            }
          }}
        ></Cascader>
      </div>
      <div className="item bb">
        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/a_detail.png" className="item_iamge" />
        <div className="item_label">详细地址</div>
        <Input className="item_input" value={state.address} onChange={(e) => setState({ ...state, address: e })} placeholder="街道 小区 楼层 门牌号" placeholder-style="color: #999999;" />
      </div>
      <div className="item">
        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/a_default.png" className="item_iamge" />
        <div className="item_label">设为默认地址</div>
        <Checkbox
          style={{
            '--icon-size': '20px',
            '--font-size': '14px',
            '--gap': '6px'
          }}
          checked={state.is_default}
          onChange={() => setState({ ...state, is_default: !state.is_default })}
        ></Checkbox>
      </div>
      <div className="btn" onClick={() => save()}>
        保存地址
      </div>
      <div className="delete" onClick={() => setDeleteModel(!0)}>
        删除地址
      </div>
      {deleteModel ? (
        <div class="deleteModel">
          <div class="delete_main">
            <div class="delete_content">您确定删除这个地址？</div>
            <div class="delete_buttons">
              <div class="delete_cancle" onClick={() => setDeleteModel(!1)}>
                取消
              </div>
              <div
                class="delete_confirm"
                onClick={() => {
                  axios
                    .post('/JuTwitter/Address/del', {
                      id: state.id,
                      uniacid: uniacid
                    })
                    .then((res) => {
                      if (res.code == 0) {
                        Toast.show({
                          content: '删除成功',
                          icon: 'success'
                        })
                        navigate(-1)
                      } else {
                        Toast.show({
                          content: res.msg
                        })
                      }
                    })
                }}
              >
                确定
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default AddAddress
