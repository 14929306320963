import React, { useState, useEffect, useRef } from 'react'
import { Image, Input, Toast, Radio, Space, Switch } from 'antd-mobile'
import { CloseCircleFill } from 'antd-mobile-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { RightOutline } from 'antd-mobile-icons'
import axios from '../../util/axios'
import { GetCharLength } from '../../util/utils'
import style from './index.module.scss'

import addImg from '../../image/add_img.png'
import _ from 'lodash'
import City from '../../components/city'
function Activation() {
  const [state, setState] = useState({
    merchant_name: '',
    contact_name: '',
    store_phone: '',
    store_site: '',
    store_logo: '',
    business_license: '',
    province_id: '',
    city_id: '',
    area_id: '',
    area_name: '',
    is_new_version: 1,
    store_longitude: '',
    store_latitude: '',
    is_catering: 0,
    food_permit: ''
  })
  const [visible, setVisible] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  let logoRef = useRef()
  let business_license_ref = useRef()
  let food_permit = useRef()
  const [checked, setChecked] = useState(false)
  let upFile = (type) => {
    let formData = new FormData()
    formData.append('file', type == 1 ? logoRef.current.files[0] : type == 2 ? business_license_ref.current.files[0] : food_permit.current.files[0])
    formData.append('sn', localStorage.getItem('sn'))
    formData.append('utoken', localStorage.getItem('utoken'))
    Toast.show({
      duration: 0,
      icon: 'loading',
      content: '上传中...'
    })
    axios.uploadFile('/JuTwitter/Upload/index', formData).then((res) => {
      Toast.clear()
      if (res.code === 0) {
        if (type == 1) {
          setState({
            ...state,
            store_logo: res.result.path
          })
        } else if (type == 2) {
          axios
            .post('/JuTwitter/VerifyBusinessLicense/verify', {
              business_license: res.result.path
            })
            .then((verifyRes) => {
              if (verifyRes.code == 0) {
                if (verifyRes.result.ErrorCode == 0) {
                  setState({
                    ...state,
                    business_license: res.result.path
                  })
                } else {
                  Toast.show({
                    content: '请上传正确的营业执照'
                  })
                }
              } else {
                Toast.show({
                  content: verifyRes.msg
                })
              }
            })
        } else {
          setState({
            ...state,
            food_permit: res.result.path
          })
        }
      } else {
        Toast.show({
          content: res.msg
        })
      }
    })
  }
  let confirm = ({ ids, name }) => {
    setState({
      ...state,
      province_id: ids[0],
      city_id: ids[1],
      area_id: ids[2],
      area_name: name
    })
    setVisible(!1)
  }
  let getInfo = () => {
    axios.post('/JuTwitter/StoreSettled/info').then((res) => {
      if (res.code === 0) {
        setState(res.result)
      }
    })
  }
  let submit = () => {
    try {
      if (!state.merchant_name) throw '请输入店铺名称'
      if (GetCharLength(state.merchant_name) > 16) throw '店铺名称不可超过8个字'
      if (!state.contact_name) throw '请输入店长姓名'
      if (!state.store_phone) throw '请输入店长电话'
      if (!state.store_site) throw '请输入店铺地址'
      if (!state.province_id) throw '请选择所属区域'
      if (!state.store_logo) throw '请添加店铺logo'
      if (!state.business_license) throw '请添加经营资质'
      if (!state.store_longitude) throw '请选择坐标'
      if (state.is_catering && !state.food_permit) throw '请上传食品经营许可证'
      axios
        .post('/JuTwitter/StoreSettled/submit', {
          utoken: localStorage.getItem('utoken'),
          merchant_name: state.merchant_name,
          contact_name: state.contact_name,
          store_phone: state.store_phone,
          store_site: state.store_site,
          store_logo: state.store_logo,
          business_license: state.business_license,
          province_id: state.province_id,
          city_id: state.city_id,
          area_id: state.area_id,
          is_new_version: state.is_new_version,
          store_longitude: state.store_longitude,
          store_latitude: state.store_latitude,
          is_catering: state.is_catering,
          food_permit: state.food_permit
        })
        .then((res) => {
          if (res.code === 0) {
            Toast.show({
              icon: 'success',
              content: '提交成功'
            })
            navigate('/reviewProgress', { state: 1 })
          } else {
            Toast.show({
              content: res.msg
            })
          }
        })
    } catch (e) {
      Toast.show({
        content: e
      })
    }
  }
  let popstate = () => {
    navigate('/activation')
  }
  useEffect(() => {
    if (location.state === 3) {
      getInfo()
    } else if (location.state === 4) {
      let activation = JSON.parse(localStorage.getItem('activation'))
      setState(activation)
    } else {
      window.addEventListener('popstate', popstate, false)
      return () => {
        window.removeEventListener('popstate', popstate, false)
      }
    }
  }, [])
  return (
    <div className={style.content}>
      {/*<div className={style.banner}>
        <Image src={banner} />
      </div>
      <div className={style.intro}>
        <div>
          <Image src={wifi} />
          <span>一键快速连接</span>
        </div>
        <div>
          <Image src={pass} />
          <span>无需告知密码</span>
        </div>
        <div>
          <Image src={security} />
          <span>安全防蹭网</span>
        </div>
      </div>*/}
      <div className={style.formInfo}>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            店铺名称
          </div>
          <div className={style.put}>
            <Input placeholder="请输入店铺名称" value={state.merchant_name} onChange={(e) => setState({ ...state, merchant_name: e })} />
          </div>
        </div>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            商家姓名
          </div>
          <div className={style.put}>
            <Input placeholder="请输入店长姓名" value={state.contact_name} onChange={(e) => setState({ ...state, contact_name: e })} />
          </div>
        </div>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            商家电话
          </div>
          <div className={style.put}>
            <Input placeholder="请输入店长电话" value={state.store_phone} onChange={(e) => setState({ ...state, store_phone: e })} />
          </div>
        </div>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            店铺地址
          </div>
          <div className={`${style.put} ${style.address}`}>
            <div style={{ flex: 1 }}>
              <Input placeholder="请输入店铺地址" value={state.store_site} onChange={(e) => setState({ ...state, store_site: e })} />
            </div>
          </div>
        </div>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            <div style={{ flex: 1 }}>选择坐标</div>
            <div
              className={style.locationbtn}
              onClick={() => {
                localStorage.setItem('activation', JSON.stringify(state))
                navigate('/map')
              }}
            >
              选择
            </div>
          </div>
          <div className={style.title} style={{ alignItems: 'center' }}>
            <div className={style.stitle}>经度：</div>
            <Input value={state.store_longitude} disabled className={style.locationinput} />
          </div>
          <div className={style.title} style={{ alignItems: 'center' }}>
            <div className={style.stitle}>纬度：</div>
            <Input value={state.store_latitude} disabled className={style.locationinput} />
          </div>
        </div>
        {/*<div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            <div className={style.flex1}>点餐系统是否支持餐位+打包费</div>
            <Radio.Group defaultValue={state.is_new_version}>
              <Space>
                <Radio
                  value={1}
                  style={{
                    '--icon-size': '18px',
                    '--font-size': '14px',
                    '--gap': '6px'
                  }}
                >
                  是
                </Radio>
                <Radio
                  value={0}
                  style={{
                    '--icon-size': '18px',
                    '--font-size': '14px',
                    '--gap': '6px'
                  }}
                >
                  否
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className={style.tips}>（此系统选择“是”并确认提交后不可修改，请谨慎选择!）</div>
        </div>*/}
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            所属区域
          </div>
          <div className={`${style.put} ${style.address}`} onClick={() => setVisible(true)}>
            {state.area_name ? <span>{state.area_name}</span> : <Input placeholder="请选择所属区域" disabled />}
            <RightOutline fontSize={15} color="#333" />
            <City visible={visible} cancel={setVisible} confirm={confirm} type={2}></City>
          </div>
        </div>
        <div className={style.formItem} style={{ border: 0 }}>
          <div className={style.title}>
            <span>*</span>
            店铺logo
            <span style={{ fontWeight: 400, color: '#999' }}>（务必上传店铺门头照）</span>
          </div>
          <div className={style.addLogo}>
            <div className={style.demonstration}>
              <Image src="https://staticfile.cnweisou.net/Upload/images/20220928/38b1bdeefbffd0e6d0c7476c5639c31f-未标题-1.png" />
              <div>店铺logo上传示范</div>
            </div>
            <div className={style.upLogo}>
              {state.store_logo ? (
                <div className={style.logo}>
                  <Image src={state.store_logo} />
                  <div className={style.close} onClick={() => setState({ ...state, store_logo: '' })}>
                    <CloseCircleFill color="#ff5a01" fontSize={20} />
                  </div>
                </div>
              ) : (
                <div className={style.add}>
                  <Image src={addImg} />
                  <div>添加</div>
                  <input ref={logoRef} type="file" accept="image/png,image/jpeg,image/jpg" onChange={() => upFile(1)} />
                </div>
              )}
              <div className={style.tips}>建议尺寸：300*300</div>
            </div>
          </div>
        </div>
        <div className={style.formItem} style={{ border: 0 }}>
          <div className={style.title}>
            <span>*</span>
            经营资质
          </div>
          <div className={style.business_license} style={{ backgroundImage: 'url(' + state.business_license + ')', border: state.business_license ? 0 : '' }}>
            {state.business_license ? (
              <div className={style.close} onClick={() => setState({ ...state, business_license: '' })}>
                <CloseCircleFill color="#ff5a01" fontSize={20} />
              </div>
            ) : (
              <div className={style.add_business_license}>
                <Image src={addImg} />
                <div>添加资质照片</div>
                <input ref={business_license_ref} type="file" accept="image/png,image/jpeg,image/jpg" onChange={() => upFile(2)} />
              </div>
            )}
          </div>
        </div>
        <div className={style.formItem} style={{ border: 0 }}>
          <div className={style.title}>
            <span>*</span>
            <div className={style.flex1}>是否是餐饮行业</div>
            <Switch
              style={{
                '--height': '22px',
                '--width': '40px'
              }}
              checked={checked}
              onChange={(val) => {
                setChecked(val)
                setState({ ...state, is_catering: val ? 1 : 0 })
              }}
            />
          </div>
        </div>
        {state.is_catering == 1 ? (
          <div className={style.formItem} style={{ border: 0 }}>
            <div className={style.title}>
              <span>*</span>
              食品经营许可证
            </div>
            <div className={style.business_license} style={{ backgroundImage: 'url(' + state.food_permit + ')', border: state.food_permit ? 0 : '' }}>
              {state.food_permit ? (
                <div className={style.close} onClick={() => setState({ ...state, food_permit: '' })}>
                  <CloseCircleFill color="#ff5a01" fontSize={20} />
                </div>
              ) : (
                <div className={style.add_business_license}>
                  <Image src={addImg} />
                  <div>添加食品经营许可证</div>
                  <input ref={food_permit} type="file" accept="image/png,image/jpeg,image/jpg" onChange={() => upFile(3)} />
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div className={style.btn} onClick={() => submit()}>
        确认提交
      </div>
    </div>
  )
}

export default Activation
