import React, { useState, useEffect } from 'react';
import { Image, Input, Toast, Swiper } from 'antd-mobile'
import axios from '../../util/axios';
import style from "./index.module.scss";
function Pay() {
    const [state, setState] = useState({
        keyboard: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'],
        amount: '',
        store_name: '',
        store_logo: '',
        quanzhen_pay_logo: '',
        quanzhen_pay_activity_banners: [],
        quanzhen_support: ''
    });
    let isPay = true;
    let browser = navigator.userAgent.toLowerCase();
    let payment = () => {
        try {
            let amount = state.amount;
            // 如果存在小数点，判断是否仅有一个小数点
            if (amount.indexOf(".") != amount.lastIndexOf(".")) throw "请输入正确的金额";

            //判断金额是否为零
            if (amount <= 0) throw "请输入正确的金额";

            //判断金额小数点后是否超过两位
            if (amount.indexOf(".") != -1) {
                let str = amount.substr(amount.indexOf(".") + 1);
                if (str.length > 2 || str.length < 1) throw "请输入正确的金额";
            }
            //判断小数点是否在第一位
            if (amount.substring(0, 1) == '.') throw "请输入正确的金额";

            //判断以零开头的金额，小数点是否在第一位
            if (amount.charAt(0) == "0" && amount.indexOf(".") != 1) throw "请输入正确的金额";
            if (isPay) {
                isPay = false;
                axios.post("/JuTwitter/Payorder/index", {
                    amount
                }).then(res => {
                    if (res.code == 0) {
                        return axios.post("/JuTwitter/Payorder/renewPay", { id: res.result });
                    } else {
                        isPay = true;
                        Toast.show({ content: res.msg })
                    }
                }).then(res => {
                    isPay = true;
                    if (res.code == 0) {
                        window.location.href = res.result.payUrl;
                    } else {
                        Toast.show({ content: res.msg })
                    }
                }).catch(()=>{
                    isPay = true;
                })
            }
        } catch (e) {
            Toast.show({ content: e })
        }

    }
    useEffect(() => {
        axios.post("/JuTwitter/Payorder/display").then(res => {
            if (res.code == 0) {
                setState({
                    ...state,
                    store_name: res.result.store.store_name,
                    store_logo: res.result.store.store_logo,
                    quanzhen_pay_logo: res.result.twitter_set.quanzhen_pay_logo,
                    quanzhen_pay_activity_banners:browser.match(/MicroMessenger/i) == "micromessenger" ?  res.result.twitter_set.quanzhen_pay_activity_banners : res.result.twitter_set.quanzhen_pay_ali_activity_banners,
                    quanzhen_support: res.result.twitter_set.quanzhen_support
                })
            }
        })
    }, [])
    return (
        <div className={style.content}>
            <div>
                <div className={style['pay-info']}>
                    <div className={style.logo}>
                        <Image src={state.quanzhen_pay_logo} />
                    </div>
                    <div className={style['shop-info']}>
                        <div>付款给</div>
                        <div>
                            <span>{state.store_name}</span>
                            <img src={state.store_logo} alt="" />
                        </div>
                    </div>
                    <div className={style['pay-amount']}>
                        <div className={style.title}>支付金额</div>
                        <div className={style.put}>
                            <span>￥</span>
                            <Input value={state.amount} onFocus={() => document.activeElement.blur()} onChange={e => setState({ ...state, amount: e })} />
                            {
                                state.amount ? <img src={require("../../image/icon13.png")} onClick={() => setState({ ...state, amount: '' })} /> : null
                            }
                        </div>
                        <p>您即将向商户付款，为保证安全，请您核对付款信息无误后再支付。</p>
                    </div>
                </div>
                <div className={style['pay-keyboard']}>
                    <div className={style.keyboard}>
                        {
                            state.keyboard.map((item, index) => {
                                return <div key={index} onClick={() => setState({ ...state, amount: state.amount + item })}>{item}</div>
                            })
                        }
                    </div>
                    <div className={style['clear-confirm']}>
                        <div onClick={() => setState({ ...state, amount: state.amount.substr(0, state.amount.length - 1) })} >
                            <img src={require("../../image/icon12.png")} alt="" />
                        </div>
                        <div onClick={() => payment()}>付款</div>
                    </div>
                </div>
            </div>
            <div className={style.banner}>
                {
                    state.quanzhen_pay_activity_banners.length > 1 ? <Swiper style={{
                        '--border-radius': '6px',
                    }} loop>
                        {
                            state.quanzhen_pay_activity_banners.map((item, index) => {
                                return (
                                    <Swiper.Item key={index}>
                                        <Image src={item.image} fit="cover" />
                                    </Swiper.Item>
                                )
                            })
                        }
                    </Swiper> : <Swiper style={{
                        '--border-radius': '6px',
                    }} indicator={() => null}>
                        {
                            state.quanzhen_pay_activity_banners.map((item, index) => {
                                return (
                                    <Swiper.Item key={index}>
                                        <Image src={item.image} fit="cover" />
                                    </Swiper.Item>
                                )
                            })
                        }
                    </Swiper>
                }
                <p>@{state.quanzhen_support}</p>
            </div>
        </div>
    )
}

export default Pay;