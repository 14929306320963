import style from "./index.module.scss";
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "../../util/axios";
import { RightOutline } from 'antd-mobile-icons';
import { Image } from 'antd-mobile';
import { getDateformat } from "../../util/utils";
import _ from "lodash";
function MyWallet() {
    const navigate = useNavigate();
    const location = useLocation();
    let [balanceList, setBalanceList] = useState([]);
    let getBalanceLog = () => {
        axios.post("/JuTwitter/User/getBalanceLog", {
            page: 1,
            rows: 10
        }).then(res => {
            if (res.code == 0) {
                let data = _.cloneDeep(balanceList);
                data.push(...res.result.list);
                setBalanceList(data);
            }
        })
    }
    useEffect(() => {
        getBalanceLog();
    }, [])
    return (
        <div className={style.content}>
            <div className={style.back}>
                <Image src={require("../../image/back3.png")} />
            </div>
            <div className={style.wallet_con}>
                <div className={style.wallet_money}>
                    <div className={style.title}>
                        <div>可提现余额（元）</div>
                        <div onClick={() => navigate("/withdrawalLog")}>
                            <Image src={require("../../image/my_icon6.png")} />
                            提现记录
                        </div>
                    </div>
                    <div className={style.num}>
                        <div>
                            <span>￥</span>
                            <span>{location.state.cash}</span>
                        </div>
                        <div>余额满{location.state.minimum_withdrawal_amount}可提现</div>
                    </div>
                    {
                        location.state.cash >= location.state.minimum_withdrawal_amount ? <div className={style.btn} onClick={() => navigate("/withdrawal", { state: { cash: location.state.cash, minimum_withdrawal_amount: location.state.minimum_withdrawal_amount } })}>提现</div> : null
                    }
                </div>
                {
                    balanceList.length > 0 ? <div className={style.wallet_detail}>
                        <div className={style.title}>
                            <div>余额明细</div>
                            <div onClick={() => navigate("/balanceDetails")}>
                                <span>查看全部</span>
                                <RightOutline color="#999" fontSize={12} />
                            </div>
                        </div>
                        <div className={style.list}>
                            {
                                balanceList.map((item, index) => {
                                    return (
                                        <div className={style.item} key={index}>
                                            <div className={style.left}>
                                                <div>{item.type == 1 ? '天天领现金红包' : '提现'}</div>
                                                <div>{getDateformat(item.create_time)}</div>
                                            </div>
                                            <div className={style.right}>{item.income_type == 1 ? '+' : '-'}{item.amount}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> : null
                }
            </div>
        </div>
    )
}

export default MyWallet