
import React, { useState, useEffect } from 'react';
import style from "./index.module.scss";
import { Swiper, Image } from "antd-mobile";
import axios from '../../../util/axios';
import { useLocation,useNavigate} from "react-router-dom";
function GoodsDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    let [goodsInfo, setGoodsInfo] = useState({
        picture: []
    });
    let getGoodsInfo = () => {
        axios.post("/JuTwitter/Integral/integralGoodsInfo", {
            id: location.state,
        }).then(res => {
            if (res.code == 0) {
                let info = res.result;
                info.picture = JSON.parse(info.picture);
                console.log(info)
                setGoodsInfo(info)
            }
        })
    }
    useEffect(() => {
        getGoodsInfo();
    }, [])
    return (
        <>
            <div className={style['goods-img']}>
                <Swiper style={{
                    '--height': '100%',
                }} loop indicator={(total, current) => (
                    <div className={style.customIndicator}>
                        {`${current + 1} / ${total}`}
                    </div>
                )}>
                    {
                        goodsInfo?.picture.map((item, index) => {
                            return (
                                <Swiper.Item key={index}>
                                    <Image src={item} fit="cover" />
                                </Swiper.Item>
                            )
                        })
                    }
                </Swiper>
            </div>
            <div className={style['goods-info']}>
                <div className={style['goods-title']}>{goodsInfo.name}</div>
                <div className={style['goods-price']}>
                    <span>原价{goodsInfo.prime_cost}</span><span>销量：{goodsInfo.sales}</span>
                </div>
                <div className={style['integral-price']}>
                    <div className={style.left}>
                        <div>积分兑换价：</div>
                        <div>
                            <span>{goodsInfo.integral_amount}积分</span>
                            { Number(goodsInfo.price) ? <span>+￥{goodsInfo.price}</span> : null }
                        </div>
                    </div>
                    <div className={style.right} onClick={()=> navigate("/confirmOrder",{state:goodsInfo})}>立即兑换</div>
                </div>
            </div>
            <div className={style['goods-details']} dangerouslySetInnerHTML={{ __html: goodsInfo.details }}></div>
            <div className={style.btn}>
                <div className={style['integral-price']}>
                    <span>{goodsInfo.integral_amount}积分</span>
                    { Number(goodsInfo.price) ? <span>+￥{goodsInfo.price}</span> : null }
                </div>
                <div className={style.exchange} onClick={()=> navigate("/confirmOrder",{state:goodsInfo})}>立即兑换</div>
            </div>
        </>
    )
}

export default GoodsDetails;