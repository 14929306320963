
import "./index.scss";
import React, { useState, useEffect } from 'react';
import { Mask, Toast, Dialog, Swiper } from 'antd-mobile'
import axios from "../../util/axios";
import { useNavigate } from 'react-router-dom';
let is_drawLottery = !0;
function DrawLottery() {
    const navigate = useNavigate();
    let [drawLotteryInfo, setDrawLotteryInfo] = useState({
        in_reward: [],
        is_open: 0
    })
    let [state, setState] = useState({
        status: 1,
        name: ''
    })
    let close = () => {
        setDrawLotteryInfo({
            ...drawLotteryInfo,
            is_open: 0,
        })
    }
    let shakeThreshold = 2000; // 定义一个摇动的阈值
    let lastUpdate = 0; // 记录上一次摇动的时间
    let x, y, z, lastX, lastY, lastZ; // 定义x、y、z记录三个轴的数据以及上一次触发的数据
    let deviceMotionHandler = (e) => {
        let acceleration = e.accelerationIncludingGravity; // 获取含重力的加速度
        let curTime = new Date().getTime();
        // 100毫秒进行一次位置判断
        if ((curTime - lastUpdate) > 100) {
            let diffTime = curTime - lastUpdate;
            lastUpdate = curTime;
            x = acceleration.x;
            y = acceleration.y;
            z = acceleration.z;
            let speed = Math.abs(x + y + z - lastX - lastY - lastZ) / diffTime * 10000;
            if (speed > shakeThreshold && state.status == 1 && is_drawLottery) {
                is_drawLottery = !1;
                Toast.show({
                    icon: 'loading',
                    content: '加载中…',
                    duration: 0,
                })
                axios.post("/JuTwitter/LuckyDraw/shake").then(res => {
                    Toast.clear();
                    if (res.code == 0) {
                        setState({
                            status: res.result.type == 1 ? 4 : res.result.type == 2 ? 3 : 2,
                            name: res.result.name
                        })
                        setDrawLotteryInfo({
                            ...drawLotteryInfo,
                            times: drawLotteryInfo.times - 1
                        })
                    } else {
                        Toast.show({
                            content: res.msg
                        })
                    }
                }).catch(() => Toast.clear())
            }
            lastX = x;
            lastY = y;
            lastZ = z;
        }
    }
    let ios13granted = () => {
        if (typeof window.DeviceMotionEvent.requestPermission === 'function') {
            window.DeviceMotionEvent.requestPermission().then((permissionState) => {
                if (permissionState === 'granted') {
                    window.addEventListener('devicemotion', deviceMotionHandler, false);
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }
    let iosGrantedTips = () => {
        let ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf("like mac os x") > 0) {
            let reg = /os [\d._]*/gi;
            let verinfo = ua.match(reg);
            let version = (verinfo + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, ".");
            let arr = version.split(".");
            if (arr[0] > 12 && arr[1] >= 0) {  //对13.0以后的版本处理,包括13.0,
                window.DeviceMotionEvent.requestPermission().then(permissionState => {
                    if (permissionState === 'granted') {
                        window.addEventListener('devicemotion', deviceMotionHandler, false);
                    }
                })
                    .catch((err) => {
                        Dialog.confirm({
                            content: '由于IOS系统需要手动获取访问动作与方向的权限，为了保证摇奖正常运行，请在访问提示中点击允许！',
                            onConfirm() {
                                ios13granted();
                            }
                        })
                    });
            } else {  //13.0以前的版本
                window.addEventListener('devicemotion', deviceMotionHandler, false);
            }
        } else {
            window.addEventListener('devicemotion', deviceMotionHandler, false);
        }
    }
    let getLuckDrawSet = () => {
        axios.post("/JuTwitter/LuckyDraw/getData").then(res => {
            if (res.code == 0) {
                res.result.is_open = res.result.times;
                setDrawLotteryInfo(res.result);
            }
        })
    }
    useEffect(() => {
        getLuckDrawSet();
    }, [])
    useEffect(() => {
        if (!drawLotteryInfo.is_open) {
            window.removeEventListener('devicemotion', deviceMotionHandler);
        }else{
            is_drawLottery = !0;
            iosGrantedTips();
        }
        return () => {
            window.removeEventListener('devicemotion', deviceMotionHandler);
        }
    }, [state.status, drawLotteryInfo.is_open])
    return (
        <Mask
            visible={drawLotteryInfo.is_open}
        >
            <div className="draw-lottery">
                <div className="draw-lottery-con">
                    {
                        state.status == 1 ? <div className="not-start-status">
                            <img className="close" src={require("../../image/icon16.png")} onClick={() => close()} />
                            <div className="img" style={{ backgroundImage: `url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/drawLottery.gif)` }}>
                                <div className="notice">
                                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/horn.png" alt="" />
                                    <Swiper autoplay loop direction='vertical' indicator={() => null}>
                                        {
                                            drawLotteryInfo.in_reward.map((item, index) => {
                                                return (
                                                    <Swiper.Item key={index}>恭喜{item.nick_name.slice(0, 3) + '***'}获得{item.reward}{item.type == 1 ? '元' : '积分'}</Swiper.Item>
                                                )
                                            })
                                        }
                                    </Swiper>
                                </div>
                            </div>
                            <div className="num-rules">
                                <p>剩余次数：{drawLotteryInfo.times}次</p>
                                <p onClick={() => navigate("/drawLotteryRules", { state: drawLotteryInfo.content })}>规则说明</p>
                            </div>
                        </div> : state.status == 2 ? <div className="no-prize-status">
                            <img className="close" src={require("../../image/icon16.png")} onClick={() => close()} />
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/noprize.png" alt="" />
                            <div onClick={() => setState({ ...state, status: 1 })}>再摇一次</div>
                        </div> : <div className="success-status">
                            <img className="close" src={require("../../image/icon16.png")} onClick={() => close()} />
                            <div className="integral" style={{ backgroundImage: `url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/${state.status == 3 ? 'prize-integral' : 'prize-money'}.png)` }}>
                                {
                                    <p>{state.name}</p>
                                }
                                <p>平台奖励</p>
                            </div>
                            <div className="btn" onClick={() => {
                                setState({ ...state, status: 1 })
                            }}>再摇一次</div>
                        </div>
                    }
                </div>
            </div>
        </Mask>

    )
}

export default DrawLottery;


