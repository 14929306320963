import './index.scss'
import React, { useState, useEffect } from 'react'
import axios from '../../../util/axios'
import { copyText } from '../../../util/utils'
import { Toast, Dialog } from 'antd-mobile'
import { useLocation, useNavigate } from 'react-router-dom'
function EnterpriseOrderDetails() {
  const location = useLocation()
  const navigate = useNavigate()
  let [info, setInfo] = useState({})

  let getInfo = () => {
    axios
      .post('/JuTwitter/Order/info', {
        id: location.state
      })
      .then((res) => {
        if (res.code == 0) {
          setInfo(res.result.info)
        }
      })
  }
  useEffect(() => {
    getInfo()
  }, [])
  return (
    <>
      {info?.id ? (
        <div className="enterprise-order-details">
          <div className="pay-state">
            <img
              src={`https://boweisou.oss-cn-shenzhen.aliyuncs.com/retail/${
                info.state == 40 ? 'success' : info.state == 0 ? 'close' : info.state == 10 ? 'money' : info.state == 20 ? 'car' : info.state == 30 ? 'receipt' : ''
              }.png`}
            />
            <p>{info.state_desc}</p>
          </div>
          <div className="order-con">
            <div className="address">
              <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/retail/user.png" />
              <div>
                <p>{info.extend_order_extend.reciver_infos.combine_detail}</p>
                <p>
                  {info.extend_order_extend.reciver_infos.name} {info.extend_order_extend.reciver_infos.phone}
                </p>
              </div>
            </div>
            <div className="goods-info">
              <div className="store">
                <div>
                  <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/retail/store.png" />
                  <p>{info.shop_info.shop_name}</p>
                </div>
                <span>共{info.goods_num}件</span>
              </div>
              {info.extend_order_goods.map((item, index) => {
                return (
                  <div className="goods" key={index}>
                    <img src={item.goods_img} />
                    <div className="goods-con">
                      <div className="title">
                        <div>{item.goods_title}</div>
                        <div>￥{item.goods_price}</div>
                      </div>
                      <div className="spec">
                        {item.sku_info ? <div>{JSON.parse(item.sku_info.sku_attrs)[0].attr_value}</div> : null}
                        <div>x{item.goods_num}</div>
                      </div>
                      {info.state == 20 || info.state == 30 ? (
                        item.refund_state == 3 ? (
                          <div className="redText">退款成功</div>
                        ) : (
                          <div
                            className="refund-state"
                            onClick={() => {
                              if (item.refund_state == 1) {
                                navigate('/afterSales', { state: { data: item, state: info.state } })
                              } else {
                                navigate('/afterSalesProgress', { state: item.refund_id })
                              }
                            }}
                          >
                            {item.refund_state == 1 ? '申请售后' : '维权进度'}
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="order-info">
              <div className="info-item">
                <div>订单号</div>
                <div>
                  <span
                    onClick={(e) => {
                      copyText(e, info.pay_sn).then(() => {
                        Toast.show({
                          content: '已复制'
                        })
                      })
                    }}
                  >
                    复制
                  </span>{' '}
                  {info.pay_sn}
                </div>
              </div>
              <div className="info-item">
                <div>下单时间</div>
                <div>{info.create_time}</div>
              </div>
              <div className="info-item">
                <div>商品总价</div>
                <div>￥{info.goods_amount}</div>
              </div>
              {/*{info.reduction_amount * 1 > 0 ? (
                <div className="info-item">
                  <div>优惠</div>
                  <div>-￥{info.reduction_amount}</div>
                </div>
              ) : null}*/}
              <div className="info-item">
                <div>运费</div>
                {info.revise_freight_fee > 0 ? <div>￥{info.revise_freight_fee}</div> : <div>￥{(info.freight_fee - info.freight_reduction_amount).toFixed(2)}</div>}
              </div>
              <div className="info-item">
                <div>订单总计</div>
                <div style={{ color: '#FF2626' }}>￥{info.amount}</div>
              </div>
            </div>
            {info.message ? (
              <div className="message">
                <div>备注</div>
                <div>{info.message}</div>
              </div>
            ) : null}
          </div>
          <div className="btn-state">
            {info.state == 10 ? (
              <div
                onClick={() => {
                  Dialog.confirm({
                    content: `确认取消订单？`,
                    onConfirm: () => {
                      axios
                        .post('/JuTwitter/Order/cancel', {
                          id: info.id
                        })
                        .then((res) => {
                          if (res.code == 0) {
                            getInfo()
                            Toast.show({
                              content: '已取消'
                            })
                          } else {
                            Toast.show({
                              content: res.msg
                            })
                          }
                        })
                    }
                  })
                }}
              >
                取消订单
              </div>
            ) : null}
            {info.state == 30 || info.state == 40 ? <div onClick={() => navigate('/logistics', { state: { id: info.id, type: 2 } })}>查看物流</div> : null}
            {info.state == 10 ? (
              <div
                onClick={() => {
                  axios
                    .post('/JuTwitter/Buy/pay', {
                      payment_channel: 'eyun_pay',
                      pay_sn: info.pay_sn
                    })
                    .then((res) => {
                      if (res.code == 0) {
                        window.location.href = res.result.payUrl
                      } else {
                        Toast.show({
                          content: res.msg || res.result
                        })
                      }
                    })
                }}
              >
                去付款
              </div>
            ) : null}
            {info.state == 30 ? (
              <div
                onClick={() => {
                  Dialog.confirm({
                    content: `是否确认收货？`,
                    onConfirm: () => {
                      axios
                        .post('/JuTwitter/Order/confirmReceipt', {
                          id: info.id
                        })
                        .then((res) => {
                          if (res.code == 0) {
                            getInfo()
                            Toast.show({
                              content: '已收货'
                            })
                          } else {
                            Toast.show({
                              content: res.msg
                            })
                          }
                        })
                    }
                  })
                }}
              >
                确认收货
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default EnterpriseOrderDetails
